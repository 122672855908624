import { Blur, Card } from "@clipboard-health/ui-components";
import { CardContent, Stack } from "@mui/material";

export function ObscuredPlacementCard() {
  return (
    <Card sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}>
      <CardContent>
        <Stack spacing={8}>
          {/* Top section with facility name and distance */}
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
            <Stack spacing={4} flex={1}>
              <Stack direction="column" spacing={1}>
                {/* Facility name placeholder */}
                <Blur sx={{ width: "200px", height: "1.5rem" }} />
                {/* Distance placeholder */}
                <Blur sx={{ width: "120px", height: "1rem" }} />
              </Stack>
            </Stack>

            {/* Worker type chip placeholder */}
            <Blur sx={{ width: "80px", height: "1.5rem" }} />
          </Stack>

          {/* Bottom section with job details and pay */}
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            <Stack spacing={1}>
              {/* Job type placeholder */}
              <Blur sx={{ width: "160px", height: "1rem" }} />
              {/* Shift type placeholder */}
              <Blur sx={{ width: "120px", height: "1rem" }} />
            </Stack>

            {/* Pay rate placeholder */}
            <Blur sx={{ width: "100px", height: "2.5rem" }} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
