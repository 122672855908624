import { Input, TextArea } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Box, Checkbox, FormControlLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type ControllerFieldState, type ControllerRenderProps } from "react-hook-form";

import { AddressField } from "./AddressField";
import { type FieldDefinition } from "./formSchema";
import { PhoneField } from "./PhoneField";

interface ElementFieldProps {
  fieldDefinition: FieldDefinition;
  field: ControllerRenderProps<Record<string, unknown>, string>;
  fieldState: ControllerFieldState;
}

export function ElementField(props: ElementFieldProps) {
  const { fieldDefinition, field, fieldState } = props;
  switch (fieldDefinition.type) {
    case "input": {
      return <Input {...field} id={fieldDefinition.id} placeholder={fieldDefinition.placeholder} />;
    }

    case "textarea": {
      return <TextArea {...field} placeholder={fieldDefinition.placeholder} rows={3} />;
    }

    case "select": {
      return (
        <Select {...field} id={fieldDefinition.id}>
          {fieldDefinition.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      );
    }

    case "address": {
      return <AddressField fieldDefinition={fieldDefinition} />;
    }

    case "numeric": {
      return (
        <Input
          {...field}
          id={fieldDefinition.id}
          placeholder={fieldDefinition.placeholder}
          inputMode="numeric"
        />
      );
    }

    case "phone": {
      return <PhoneField fieldDefinition={fieldDefinition} />;
    }

    case "date": {
      return <DatePicker {...field} disableFuture />;
    }

    case "boolean": {
      return (
        <Box>
          <FormControlLabel
            control={<Checkbox {...field} />}
            label={fieldDefinition.label}
            sx={{ marginLeft: -4 }}
          />
          {fieldState.error?.message ? (
            <Text variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
              {fieldState.error.message}
            </Text>
          ) : undefined}
        </Box>
      );
    }

    default: {
      return null;
    }
  }
}
