import { startCase } from "lodash";
import { z } from "zod";

const fieldBaseSchema = z.object({
  id: z.string(),
  label: z.string(),
  optional: z.boolean().optional(),
});

const selectOptionSchema = z.object({
  value: z.string(),
  label: z.string(),
});

const textInputFieldSchema = fieldBaseSchema.extend({
  type: z.literal("input"),
  placeholder: z.string().optional(),
});

const textareaFieldSchema = fieldBaseSchema.extend({
  type: z.literal("textarea"),
  placeholder: z.string().optional(),
});

const numericFieldSchema = fieldBaseSchema.extend({
  type: z.literal("numeric"),
  placeholder: z.string().optional(),
});

const phoneFieldSchema = fieldBaseSchema.extend({
  type: z.literal("phone"),
  placeholder: z.string().optional(),
});

const addressFieldSchema = fieldBaseSchema.extend({
  type: z.literal("address"),
  placeholder: z.string().optional(),
});

const dateFieldSchema = fieldBaseSchema.extend({
  type: z.literal("date"),
});

const selectFieldSchema = fieldBaseSchema.extend({
  type: z.literal("select"),
  options: z.array(selectOptionSchema),
});

const yesNoFieldSchema = fieldBaseSchema.extend({
  type: z.literal("boolean"),
  options: z.array(selectOptionSchema),
});

const groupSchema = z.object({
  type: z.literal("group"),
  description: z.string(),
  fields: z.array(numericFieldSchema),
});

const formFieldSchema = z.discriminatedUnion("type", [
  textInputFieldSchema,
  textareaFieldSchema,
  numericFieldSchema,
  phoneFieldSchema,
  dateFieldSchema,
  selectFieldSchema,
  yesNoFieldSchema,
  addressFieldSchema,
]);

const fieldSchema = z.union([formFieldSchema, groupSchema]);

const formSectionSchema = z.object({
  description: z.string().optional(),
  fields: z.array(fieldSchema),
});

export const formSchema = z.object({
  sections: z.array(formSectionSchema),
});

export type FieldDefinition = z.infer<typeof fieldSchema>;
export type FormFieldDefinition = z.infer<typeof formFieldSchema>;
export type FormSectionDefinition = z.infer<typeof formSectionSchema>;
export type FormDefinition = z.infer<typeof formSchema>;
export type AddressFieldDefinition = z.infer<typeof addressFieldSchema>;
export type PhoneFieldDefinition = z.infer<typeof phoneFieldSchema>;

export type FormData = Record<string, string | number>;

function getZodType(fieldDefinition: FormFieldDefinition) {
  const zodParams = { required_error: `${startCase(fieldDefinition.id)} is required` };

  if (fieldDefinition.type === "date") {
    return z.date(zodParams).transform((value) => value.toISOString());
  }

  if (fieldDefinition.type === "numeric") {
    return z.string(zodParams).refine((value) => value === "" || Number.parseFloat(value), {
      message: "Number required",
    });
  }

  if (fieldDefinition.type === "boolean") {
    return z.boolean(zodParams);
  }

  return z.string(zodParams);
}

export function getFormFields(fieldDefinitions: FieldDefinition[]) {
  return fieldDefinitions.flatMap((fieldDefinition) =>
    fieldDefinition.type === "group" ? fieldDefinition.fields : fieldDefinition
  );
}

export function createZodResolver(fieldDefinitions: FieldDefinition[]) {
  const zodSchema = getFormFields(fieldDefinitions).reduce((schema, fieldDefinition) => {
    const validator = getZodType(fieldDefinition);

    return {
      ...schema,
      [fieldDefinition.id]: fieldDefinition.optional ? validator.optional() : validator,
    };
  }, {});
  return z.object(zodSchema);
}
