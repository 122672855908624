import { API_TYPES, post } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

const paySurveyAnswer = z.object({
  questionId: z.string(),
  answer: z.union([z.string(), z.number()]),
});
export const createPaySurveyResponseBodySchema = z.object({
  data: z.object({
    type: z.literal(API_TYPES.paySurveyResponse),
    attributes: z.object({
      answers: z.array(paySurveyAnswer),
    }),
    relationships: z.object({
      sheet: z.object({
        data: z.object({
          type: z.literal(API_TYPES.sheet),
          id: z.string(),
        }),
      }),
    }),
  }),
});

export type CreatePaySurveyResponseRequest = z.infer<typeof createPaySurveyResponseBodySchema>;
export type PaySurveyAnswer = z.infer<typeof paySurveyAnswer>;

interface CreatePaySurveyResponseParams {
  answers: PaySurveyAnswer[];
  sheetId: string;
}

export function useCreatePaySurveyResponse(
  options?: UseMutationOptions<undefined, unknown, CreatePaySurveyResponseParams>
) {
  return useMutation({
    mutationFn: async (params: CreatePaySurveyResponseParams) => {
      const { sheetId, answers } = params;
      await post({
        url: `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/placements/pay-survey-response`,
        requestSchema: z.unknown(),
        responseSchema: z.unknown(),
        data: {
          data: {
            type: "pay-survey-response",
            attributes: {
              answers,
            },
            relationships: {
              sheet: {
                data: {
                  type: "sheet",
                  id: sheetId,
                },
              },
            },
          },
        },
      });
      return undefined;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.PLACEMENT_PAY_SURVEY_RESPONSE_FAILURE,
      logSuccessMessage: APP_V2_APP_EVENTS.PLACEMENT_PAY_SURVEY_RESPONSE_SUCCESS,
      userErrorMessage: "Something went wrong when submitting the survey",
    },
    ...options,
  });
}
