import { Input } from "@clipboard-health/ui-components";
import { type InputBaseComponentProps } from "@mui/material";
import { forwardRef, type FunctionComponent } from "react";
import { useController } from "react-hook-form";
import { IMaskInput } from "react-imask";

import { type PhoneFieldDefinition } from "./formSchema";

interface PhoneNumberMaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const PhoneNumberMask = forwardRef<HTMLInputElement, PhoneNumberMaskProps>((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      overwrite
      mask="(000) 000-0000"
      definitions={{
        "#": /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value: string) => {
        onChange({ target: { name: props.name, value } });
      }}
    />
  );
});

PhoneNumberMask.displayName = "PhoneNumberMask";

interface PhoneFieldProps {
  fieldDefinition: PhoneFieldDefinition;
}

export function PhoneField(props: PhoneFieldProps) {
  const { fieldDefinition } = props;
  const { field } = useController({
    name: fieldDefinition.id,
  });

  return (
    <Input
      {...field}
      fullWidth
      id={fieldDefinition.id}
      type="tel"
      placeholder={fieldDefinition.placeholder}
      inputComponent={PhoneNumberMask as unknown as FunctionComponent<InputBaseComponentProps>}
    />
  );
}
