import { Input } from "@clipboard-health/ui-components";
import { Autocomplete } from "@react-google-maps/api";
import { useState } from "react";
import { useController } from "react-hook-form";

import { type AddressFieldDefinition } from "./formSchema";

interface AddressFieldProps {
  fieldDefinition: AddressFieldDefinition;
}

export function AddressField(props: AddressFieldProps) {
  const { fieldDefinition } = props;
  const { field } = useController({
    name: fieldDefinition.id,
  });
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>(
    undefined
  );

  return (
    <Autocomplete
      onLoad={setAutocomplete}
      onPlaceChanged={() => {
        const place = autocomplete?.getPlace();
        if (place?.geometry?.location) {
          field.onChange(place.formatted_address);
        }
      }}
    >
      <Input id={fieldDefinition.id} placeholder={fieldDefinition.placeholder} {...field} />
    </Autocomplete>
  );
}
