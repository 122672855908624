import { Text } from "@clipboard-health/ui-react";
import { InputLabel, Stack } from "@mui/material";
import { type Control, Controller } from "react-hook-form";

import { ElementField } from "./ElementField";
import { type FormFieldDefinition, type FormSectionDefinition } from "./formSchema";

interface PayRateSurveyStepProps {
  formSection: FormSectionDefinition;
  control: Control;
}

export function PayRateSurveyStep(props: PayRateSurveyStepProps) {
  const { formSection, control } = props;

  const renderFormFieldDefinition = (
    fieldDefinition: FormFieldDefinition,
    direction: "row" | "column" = "column"
  ) => {
    const isCustomizedStyle = fieldDefinition.type === "boolean";
    const isInlineForm = direction === "row";

    return (
      <Controller
        key={fieldDefinition.id}
        name={fieldDefinition.id}
        control={control}
        render={({ field, fieldState }) => {
          return isCustomizedStyle ? (
            <ElementField fieldDefinition={fieldDefinition} field={field} fieldState={fieldState} />
          ) : (
            <Stack
              spacing={2}
              direction={direction}
              alignItems={isInlineForm ? "center" : undefined}
            >
              <InputLabel
                htmlFor={fieldDefinition.id}
                sx={{ whiteSpace: "normal", flexBasis: isInlineForm ? "90%" : undefined }}
              >
                {fieldDefinition.label}
              </InputLabel>
              <ElementField
                fieldDefinition={fieldDefinition}
                field={field}
                fieldState={fieldState}
              />
              {fieldState.error?.message ? (
                <Text variant="body2" sx={{ color: (theme) => theme.palette.error.main }}>
                  {fieldState.error.message}
                </Text>
              ) : undefined}
            </Stack>
          );
        }}
      />
    );
  };

  return (
    <Stack spacing={8}>
      {formSection.description ? <Text>{formSection.description}</Text> : undefined}
      <Stack spacing={7}>
        {formSection.fields.map((fieldDefinition) => {
          if (fieldDefinition.type === "group") {
            return (
              <Stack key={fieldDefinition.description} spacing={5}>
                <Text>{fieldDefinition.description}</Text>
                {fieldDefinition.fields.map((fieldDefinition) =>
                  renderFormFieldDefinition(fieldDefinition, "row")
                )}
              </Stack>
            );
          }

          return renderFormFieldDefinition(fieldDefinition);
        })}
      </Stack>
    </Stack>
  );
}
