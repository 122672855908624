import { BottomSheet, Divider } from "@clipboard-health/ui-components";
import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent, GlobalStyles, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { formatCentsAsUsd } from "@src/appV2/lib/Money/utils/currency";
import { Button } from "@src/appV2/redesign/components/Button";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import { LoadingButton } from "@src/appV2/redesign/components/LoadingButton";
import { type SurveyForm } from "@src/appV2/redesign/Placements/lib/usePayTransparencyFlag";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { createZodResolver, type FormData } from "./formSchema";
import { PayRateSurveyStep } from "./PayRateSurveyStep";
import { useCreatePaySurveyResponse } from "./useCreatePaySurveyResponse";

interface PayRateSurveyBottomSheetProps {
  modalState: UseModalState;
  onSubmit: () => void;
  surveyForm: SurveyForm;
  surveySheetId: string;
  bonusAmountInMinorUnits: number;
}

export function PayRateSurveyBottomSheet(props: PayRateSurveyBottomSheetProps) {
  const { modalState, onSubmit, surveyForm, surveySheetId, bonusAmountInMinorUnits } = props;
  const { sections: payRateFormSteps } = surveyForm;
  const [stepIndex, setStepIndex] = useState(0);
  const { showSuccessToast } = useToast();

  useLogEffect(
    APP_V2_APP_EVENTS.PLACEMENT_PAY_SURVEY_VIEWED,
    {
      surveySheetId,
    },
    { enabled: modalState.modalIsOpen }
  );

  const { mutateAsync: createPayResponse, isLoading: isCreatePayResponseLoading } =
    useCreatePaySurveyResponse();
  const payRateSurveyForm = useForm<FormData>({
    resolver: zodResolver(createZodResolver(payRateFormSteps.flatMap((step) => step.fields))),
    mode: "onSubmit",
  });
  const { handleSubmit, control } = payRateSurveyForm;

  const formSection = payRateFormSteps[stepIndex - 1];
  const isLastStep = stepIndex === payRateFormSteps.length;

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        stepIndex === 0 ? (
          <DialogFooter
            orientation="vertical"
            onClose={() => {
              modalState.closeModal();
            }}
          >
            <Button
              key="next"
              fullWidth
              variant="contained"
              size="large"
              onClick={() => {
                setStepIndex((previousState) => previousState + 1);
              }}
            >
              Let&apos;s go
            </Button>
          </DialogFooter>
        ) : undefined
      }
    >
      <DialogContent>
        <FormProvider {...payRateSurveyForm}>
          <Stack
            spacing={8}
            component="form"
            onSubmit={handleSubmit(async (formData) => {
              await createPayResponse({
                sheetId: surveySheetId,
                answers: Object.keys(formData)
                  .filter((fieldId) => isDefined(formData[fieldId]))
                  .map((fieldId) => ({
                    questionId: fieldId,
                    answer: formData[fieldId],
                  })),
              });
              modalState.closeModal();
              onSubmit();
              showSuccessToast(
                `You have completed the survey, and unlocked pay rate. You will receive ${formatCentsAsUsd(
                  bonusAmountInMinorUnits
                )} instant pay`
              );
            })}
          >
            <Stack direction="column" spacing={6}>
              <Text variant="h3">Community Pay Rate Survey</Text>
            </Stack>
            <Divider
              variant="fullWidth"
              sx={{ borderBottomColor: (theme) => theme.palette.border?.primary }}
            />
            <GlobalStyles
              styles={{
                ".pac-container": {
                  zIndex: 1300,
                },
              }}
            />
            {stepIndex === 0 ? (
              <Stack spacing={6}>
                <Text>
                  Help guide job-seekers in your community by sharing insights from your most recent
                  permanent position.
                </Text>
                <Text>
                  You&apos;ll receive{" "}
                  <b>
                    {formatCentsAsUsd(bonusAmountInMinorUnits, { maximumFractionDigits: 0 })} via
                    InstantPay
                  </b>{" "}
                  when you complete the survey
                </Text>
              </Stack>
            ) : undefined}
            {stepIndex > 0 ? (
              <PayRateSurveyStep
                key={formSection.description}
                control={control}
                formSection={formSection}
              />
            ) : undefined}
            {stepIndex > 0 ? (
              <Stack justifyContent="space-between" direction="row" flexDirection="row-reverse">
                {isLastStep ? (
                  <LoadingButton
                    key="submit"
                    variant="contained"
                    type="submit"
                    isLoading={isCreatePayResponseLoading}
                    disabled={isCreatePayResponseLoading}
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Button
                    key="next"
                    variant="outlined"
                    onClick={() => {
                      setStepIndex((previousState) => previousState + 1);
                    }}
                  >
                    Next
                  </Button>
                )}
                <Button
                  variant="outlined"
                  onClick={() => {
                    setStepIndex((previousState) => previousState - 1);
                  }}
                >
                  Prev
                </Button>
              </Stack>
            ) : undefined}
          </Stack>
        </FormProvider>
      </DialogContent>
    </BottomSheet>
  );
}
